.site-header {
	margin-top: 20px;
	padding-top: 20px;
	position: relative;
	z-index: 2;
	background-color: #FFF;
}





@media (width <= 767px) {
	.site-header {
		padding: 15px 15px 0;
		margin-top: 0;
	}
}