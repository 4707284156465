.owl-theme .owl-controls .owl-page span { background-color: var(--activeColor); }

.section-header {
	font-family: "Open Sans";
	font-size: 13px;
	font-weight: 300;
	text-transform: uppercase;
}

.article-header {
	font-family: "Open Sans";
	font-size: 18px;
	font-weight: 700;
}

.article-excerpt {
	font-family: "Open Sans";
	font-size: 15px;
	font-weight: 400;
}

.main-slider {
	margin-top: 14px;
}

@media (width <= 767px) {
	.main-slider {
		margin-top: 50px;
	}
}

.main-slider a {
	text-decoration: none;
}

.main-slider figure {
	position: relative;
}

.main-slider figcaption {
	position: absolute;
	bottom: 0;
	background-color: rgba(0,0,0,.5);
	padding: 20px;
	width: 100%;
}

.section-header--main-slider,
.article-header--main-slider,
.article-excerpt--main-slider { color: white; }

.main-slider .article-excerpt--main-slider { display: none; }

.main-slider figure:hover figcaption {
	background-color: var(--activeColor);
	@apply --transitionBackgroundColor;
}

.main-slider figure:hover .article-excerpt--main-slider {	display: block; }