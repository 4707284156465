.super-tags {
	margin-bottom: 50px;
}

.super-tag {
	display: inline-block;
	color: var(--menuColor);
	background-color: transparent;
	font-family: "Open Sans";
	font-size: 12px;
	font-weight: 400;
	text-transform: uppercase;
	padding: 10px 20px;
	border: 1px solid var(--inactiveColor);
	margin-right: 20px;
	cursor: pointer;
	@apply --transitionAll;
}

.super-tag:hover {
	border-color: var(--activeColor);
	@apply --transitionAll;
}

.super-tag--active {
	background-color: var(--activeColor);
	border-color: var(--activeColor);
	color: #FFF;
}