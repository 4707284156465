.article-teaser {
	width: 32%;
	flex-basis: 32%;
	max-width: 300px;
}

.article-teaser img,
.article-teaser h4 {
	@apply --transitionOpacityStart;
}

.article-teaser img:hover,
.article-teaser h4:hover {
	@apply --transitionOpacityEnd;
}

.article-teaser a {
	text-decoration: none;
}

.article-teaser img {
	width: 100%;
	height: auto;
	object-fit: cover;
}

.article-teaser h4 {
	color: var(--menuColor);
	font-family: "Open Sans";
	font-size: 22px;
	font-weight: 400;
}

.article-teaser h5 {
	color: var(--dateViewsColor);
	font-family: "Open Sans";
	font-size: 13px;
	font-weight: 400;
	text-transform: uppercase;
}



@media (width <= 767px) {
	.article-teaser {
		flex-basis: 100%;
		width: 100%;
		max-width: 100%;
		text-align: center;
	}

	.article-teaser:not(:last-child) {
		margin-bottom: 40px;
	}

	.article-teaser > a {
		max-width: 300px;
		display: block;
		margin: 0 auto;
	}
}
