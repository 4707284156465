.wide-card--large > a {
	width: 100%;
	flex-basis: 100%;
}

.wide-card--large figcaption {
	text-align: center;
	margin-left: 0;
	margin-top: 30px;
	width: 100%;
	flex-basis: 100%;
}

.section-header--large {
	color: var(--activeColor);
	font-size: 15px;
}

.article-header--large {
	color: var(--menuColor);
	font-size: 30px;
	@apply --transitionOpacityStart;
}

.article-header--large:hover { @apply --transitionOpacityEnd; }

.article-excerpt--large {
	font-size: 18px;
}

