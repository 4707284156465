.sidebar-section-header {
	border: 1px solid var(--activeColor);
	padding: 15px 0;
	margin-top: 0;
	margin-bottom: 30px;
	background-color: #FFF;
	@apply --transitionBackgroundColor;
}

.sidebar-section-header:not(:first-child) { margin-top: 40px; }

.sidebar-section-header a,
.sidebar-section-header span {
	text-decoration: none;
	text-transform: uppercase;
	font-size: 14px;
	color: var(--menuColor);
	text-align: center;
	display: block;
	@apply --transitionColor;
}