.article-paginator {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.article-page {
	flex-basis: 48%;
}

.article-paginator .article-page > a {
	color: var(--dateViewsColor);
	font-family: "Open Sans";
	font-size: 13px;
	font-weight: 300;
	text-decoration: none;
	text-transform: uppercase;
	@apply --transitionColor;
}

.article-paginator .article-page:last-child {
	text-align: right;
}

.article-paginator .article-page > a:hover {
	color: var(--activeColor);
}
.article-page figure {
	margin-top: 20px;	
}

.article-page figure img {
	width: 100%;
	height: auto;
	@apply --transitionOpacityStart;
}

.article-page figure img:hover {
	@apply --transitionOpacityEnd;
}

.article-page figcaption a {
	font-weight: 700;
	font-size: 18px;
	font-family: "Open Sans";
	color: var(--activeColor);
	display: block;
	text-align: left;
	text-decoration: none;
	@apply --transitionOpacityStart;
}

.article-page figcaption a:hover {
	@apply --transitionOpacityEnd;	
}

@media (width <= 991px) and (width >= 768px) {
	.article-page figcaption a {
		font-size: 15px;
	}
}


@media (width <= 479px) {
	.article-paginator {
		justify-content: center;
	}

	.article-page {
		flex-basis: 100%;
		text-align: center;
		margin-bottom: 10px;
	}

	.article-paginator .article-page > a {
		display: block;
		text-align: center;
	}

	.article-page figcaption a {
		font-size: 15px;
		text-align: center;
	}

}