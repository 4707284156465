.site-footer {
	height: var(--headerHeight);
	background-color: var(--footerBg);
	width: 100%;
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
}




@media (width <= 991px) {
	.site-footer { height: calc(var(--headerHeight) * 1.6); }
}





@media (width <= 479px) {
	.site-footer { height: calc(var(--headerHeight) * 1.65); }
}