.section-page__header {
	color: var(--menuColor);
	background-color: var(--footerBg);
	font-family: "Open Sans";
	font-size: 36px;
	font-weight: 400;
	text-transform: none;
	padding: 30px 0;
	text-align: center;
	margin-top: 10px;
	margin-bottom: 40px;
}