.left-side {
	width: var(--leftSide);
	padding-right: 40px;
}

@media (width <= 767px) {
	.left-side {
		width: 100%;
		padding: 15px;
	}
}