/* variables */
:root {
	--block-without-sidebar__header-width: 50px;
	--block-without-sidebar__header-height: 35px;
	--block-without-sidebar__header-edition: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAjCAMAAADha6m9AAAAgVBMVEUAAADjU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/3tiaAAAAAKnRSTlMA+fAPRU0o42sUCAT07bmX6NjHeGUaDNPApDGxzayNhD02Ht0jtH1wXld0tk1rAAABuElEQVQ4y63S53KrMBAF4FWh994xuPu8/wNelGtsxw42mck3Az8Y7ZGWFb3hCIt+Zxu0cZmInD7I5OD8X2TC62MA1eV98CZiQGPWQk6re1XYgRVS0BKtRZj0VQEGhRdHSbTvPBy0hW4TBlQ2Ub7tQ1y1G4v0AK6/p1d5DyUuSzMTPm7YJiONw03omehwFzM8MIJElZZPG1k+PirqtRV3j/vYMVbpBM0OWGlDVxusxa8DGrFeKUgx8ILx0Ag9Iz40scd3HDfuhSYXhidek2h19jVf4RBl9RBEpa+SeSnVPfG/xbeBZj+NTB8umkyTYXsMzqfj9EV/WB8FWubQA1s/VwWH4gJV4nNMX3vMdqb1lG/6LiaRXzWh60JppnMVcwNHIepxqKUmdbEdZVp5c5RwbIfsNFIHSYn2ESYsGPWTH30FcfXmHsOs0CdjbocqOJtaiXYwUusUYRlXTxvO47fP5qAHWGmnus24EWItJq9Xcr30+ifd31Uo2vpT3ZhYo83ozjE9fMLP9J2M8ZZxoheWaSy2YHQyp5/kaQf2Q35j1jktse00aEIwripZUSVySysI26k1bRT0t/4BYbCeLxhsQbgAAAAASUVORK5CYII=');
	--block-without-sidebar__header-fashion: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAjCAMAAADha6m9AAAAY1BMVEUAAADjU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU288HHGtAAAAIHRSTlMA4ryD8hUM+ZzYxa6MBm89JbZUTEQ26M+noXpcwZVlLUZVfx4AAAE8SURBVDjLzZPZkoMgEEVlMSIoKu5b0v//laOgxjjWAC9TuU9NFacabt8Ovl4V4qEf8eAAzA8ZYJH0IXK6IlHmgRDQSPtyR1qDxMzdAgRajSC+CEyz9EWAsNIXQaqr3RAOu/qsD91NNipL5pawN4JSOThNPwKINoYFvYsFIYUhzyXVTBUWLxcErZ+utXNxoPDDjjSxvlTrPklAOvu6xG2qiyesygJMrEjLt6rQT0tDNFqQlO9IDeZpI60sCJ72UmzLRqhl4YoDqfR8FjdmSP6Mm8BHw8JkbbECNTFmskrvkQ4fZQlacjGlLkfS0LhTd2Ni+F1js9WjtpKY4JH8FzKfkGwPmxqTBjbR4dopwecD3Ilfgkf42fLilpkvSPsRBhHBWRGlaLp+RyWfMZWdKDDGQoieJcNTZXkY/Kt+AGI0JglYTHBbAAAAAElFTkSuQmCC');
}

/* properties */
:root {
	--block-without-sidebar__header-ico {
		width: var(--block-without-sidebar__header-width);
    height: var(--block-without-sidebar__header-height);
    display: block;
    position: absolute;
    content: "";
		left: 0;
	}
}
.block-without-sidebar {
	border: 25px solid var(--footerBg);
	padding: 5px 40px 25px;
	margin-bottom: 60px;
}

.block-without-sidebar__header {
	text-align: center;
	margin-bottom: 30px;
}

.block-without-sidebar__header a {
	color: var(--menuColor);
	position: relative;
	padding-left: 70px;
	font-family: "Open Sans";
	font-size: 30px;
	font-weight: 400;
	text-decoration: none;
	@apply --transitionOpacityStart;
}

.block-without-sidebar__header a:hover {
	@apply --transitionOpacityEnd;
}

.block-without-sidebar__header--edition a::before {
	@apply --block-without-sidebar__header-ico;
	top: 5px;
	background-image: var(--block-without-sidebar__header-edition);
}

.block-without-sidebar__header--fashion a::before {
	@apply --block-without-sidebar__header-ico;
	top: 3px;
	background-image: var(--block-without-sidebar__header-fashion);
}

.block-without-sidebar__wrapper {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}