.full-screen-overlay {
	opacity: 0;
	transition: opacity .3s;
	position: fixed;
	z-index: 11;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: var(--overlayColor);
	pointer-events: none;
}

.full-screen-overlay.is-active {
	opacity: 1;
	transition: opacity .3s;
	pointer-events: auto;
}





@media (width <= 767px) { .full-screen-overlay { display: none; } }