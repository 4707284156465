.article-tags {
	display: block;
	margin-bottom: 20px;
	display: flex;
	align-items: baseline;
	flex-wrap: wrap;
}

.article-tags-heading {
	color: var(--menuColor);
	font-family: "Open Sans";
	font-size: 13px;
	font-weight: 400;
	text-transform: uppercase;
	margin-right: 15px;
}