.wide-card-small {
	border-bottom: none;
	padding-bottom: 0;
}

.article-header--small {
	font-size: 16px;
	font-weight: 400;
	color: var(--menuColor);
	margin-top: 5px;
	@apply --transitionOpacityStart;
}

.article-header--small:hover { @apply --transitionOpacityEnd; }