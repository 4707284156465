.article {
	margin-bottom: 20px;
}

.article picture,
.article img {
	width: 100%;
	height: auto;
	margin-bottom: 20px;
}

.article img {
	margin-bottom: 0;
}

.article p,
.article li {
	color: var(--menuColor);
	font-family: "Open Sans";
	font-size: 16px;
	font-weight: 400;
	line-height: 1.5;
}

.article b {
	color: var(--menuColor);
	font-family: "Open Sans";
	font-size: 16px;
	font-weight: bold;
	line-height: 1.5;
}

.article p b {
	margin-right: 5px;
}

.slide-main-wrapper + p {
	margin-top: 20px;
}

picture + p {
	margin-top: 35px;
}

.article .boldup {
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: 1px;
	font-size: 21px;
}

.article h1,
.article .h1 {
	font-size: 32px;
	font-weight: 400;
	line-height: 36px;
}

.article h2,
.article .h2 {
	font-size: 24px;
	font-weight: 600;
	line-height: 28px;
}

.article h3,
.article .h3 {
	font-size: 20px;
	font-weight: 700;
	line-height: 24px;
}

.article h4,
.article .h4 {
	font-size: 18px;
	font-weight: 700;
}

.article > a,
.article > p a {
	color: var(--menuColor);
	border-bottom: 1px solid var(--activeColor);
}

.article > a:hover,
.article > p a:hover {
	text-decoration: none;
	color: var(--activeColor);
	border-bottom: none;
}

.article ul {
	list-style: none;
}

.article ul li {
	position: relative;
}

.article ul li::before {
	position: absolute;
	content: "";
	width: 5px;
	height: 5px;
	background-color: var(--activeColor);
	border-radius: 50%;
	top: calc(50% - 2.5px);
	left: -15px;	
}

.article .cite {
	margin-top: 25px;
	margin-bottom: 25px;
	border: 15px solid #fafafa;
	padding: 40px 60px;
	font-size: 18px;
	line-height: 28px;
	position: relative;
}

.article .cite-end {
	position: relative;
	display: inline-block;
}

.article .cite::before,
.article .cite-end::before {
	width: 22px;
	height: 19px;
	position: absolute;
	content: "";
	background-image: url(../img/icon--quotes.svg);
}

.article .cite::before {
	transform: rotate(180deg);
	top: 40px;
	left: 30px;
}

.article .cite-end::before {
	top: -19px;
	left: 10px;
}

.article .advice {
	padding: 40px 40px 40px 110px;
	border: 2px dashed #c6546e;
	position: relative;
	margin-top: 25px;
	margin-bottom: 25px;
}

.article .advice::before {
	position: absolute;
	top: calc(50% - 18px);
	left: calc(55px - 18px);
	width: 36px;
	height: 36px;
	content: "";
	background-image: url(../img/icon--advice.svg);
}

.youtube {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	display: inline-block;
	overflow: hidden;
	transition: all 200ms ease-out;
	cursor: pointer;
	margin: 20px 0 35px;
}

.youtube .play {
	background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC4AAAAgCAMAAABabbp1AAAA5FBMVEUAAADAGB3lLCfPHiDjKybEGR3ZJSPUIyLlLSfUIyLlLSfRISHlLSfiKiXiKybiKybaJyTCGB3kLCbiKybFGR7WJCPiKybKHR/bJyTlLSfJHR/lLSfFGh7kLCfGGx7DGR7TIiLBGB3PICHDGR7NHyHXJSTAGB3aJyTGGh7bJiTiKiXPHyDKHB/WJCPdKSXCGB7TIiL////LFBXvqqrZIB3UGBfVLS377/DXGhfnl5fkiYndfHzQFhb3/Pzv9vbz4uLo1tbqoqPdWFfZPjzSOTvVNDPTHBz55eXlzs7ytLPndHLNKizszpooAAAAKHRSTlMA7evt0OPt7eXk4eHZoH5bF8/GlT8q98nBvbSxpaSPhWtdIgzVz8OuJBK0tQAAAStJREFUOMuVy9d6gkAQQOFRwRCk2Hs3VTAJK6KCPWra+79PFkgu3GX9ds/czfwDYXnTKOmtRlGSNE1VFSUdpSiqqmmSVGy0OiXDrMBfRnrMUbWTj7Q+5kwKvTnmTse8yM+rQ6i8CNQHU4TrYDwLVIcStXNdJpfgkdLfhwXrIQt1crX4cvbvC5fB7ydEs6njzJf4YUKXhVwCx23908xN4LfJ3FnvAo96uGFx3OYz8M4UfyNaYR633uwO54sbi8dtA88ieM4iQv/8w/dWxC3D5HPfQ7bFyefLU4hpfkdzjI8xpnnNsuyLQfvpEaHwaJODedMm+0HIZpSBti2QDF0RXoP+k0BNKIvwHoxkAV4G6PLrAgDk5VfeyoCrFPhwagBRo0G7IKeuJz/0hpj+Akgf51FRltPPAAAAAElFTkSuQmCC')no-repeat center center;
	background-size: 46px 32px;
	position: absolute;
	height: 100%;
	width: 100%;
	opacity: 1;
	filter: opacity(1);
	transition: all 0.3s ease-out;
}

.youtube .play:hover {
	opacity: .8;
	filter: opacity(.8);
}

.article-block {
	margin-top: 25px;
	margin-bottom: 25px;
	display: flex;
	align-items: center;
}

.article-block a {
	@apply --transitionOpacityStart;
}

.article-block a:hover {
	text-decoration: none;
	@apply --transitionOpacityEnd;
}

.article-block a:nth-child(1) {
	display: block;
	width: 150px;
	height: 150px;
	margin-right: 20px;
}

.article-block a:nth-child(2) {
	min-height: 150px;
	width: calc(100% - 170px);
	background-color: #FDF8F9;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 20px;
}

.article-block p:nth-child(1) {
	color: #333;
	font-family: Open Sans;
	font-size: 24px;
	font-weight: 600;
	line-height: 28px;
}

.article-block p:nth-child(2) {
	padding: 10px 0;
	margin: 10px 0;
	border-top: 1px solid var(--menuColor);
	border-bottom: 1px solid var(--menuColor);
}

.article-block b {
	text-transform: uppercase;
	font-weight: normal;
}

.need-block {
	margin-top: 25px;
	margin-bottom: 25px;
	padding: 40px 30px 20px;
	border: 15px solid #fafafa;
	display: flex;
}

.need-block ol {
	flex-basis: 50%;
	max-width: 50%;
	list-style: none;
	counter-reset: myCounter;
	padding-left: 30px;
	padding-right: 30px;
}

.need-block li {
	position: relative;
	margin-bottom: 10px;
}

.need-block li::before {
	counter-increment: myCounter;
	content: counter(myCounter);
	display: inline-block;
	position: absolute;
	left: -40px;
	border: 1px solid var(--activeColor);
	border-radius: 50%;
	width: 27px;
	height: 27px;
	text-align: center;
}


.photo-gallery {
	margin-bottom: 20px;
}

.photo-gallery .slide {
	position: relative;
	/* height: 500px; */
}

.photo-gallery__buttons {
	position: absolute;
	width: 100%;
	top: calc(50% - 25px);
	display: flex;
	justify-content: space-between;
}

.photo-gallery__button {
	width: 50px;
	height: 100px;
	background-color: rgba(0,0,0,.5);
	opacity: .1;
	transition: opacity .3s;
	position: relative;
	cursor: pointer;
}

.photo-gallery__button::before {
	position: absolute;
	content: "";
	top: calc(50% - 12px);
	left: calc(50% - 12px);
	width: 25px;
	height: 25px;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZBAMAAAA2x5hQAAAAJ1BMVEUAAAD///////////////////////////////////////////////+uPUo5AAAADHRSTlMA/J9gWqWmIZlnQDpJv71xAAAAPUlEQVQY02MgBmxA5jCJIPN0DhYgSZ0RRpEyoK7UnDMNSDy2MygOyTnogFsyBkWSlSTJAmRJYZRwWUAo4AAOWBTpHOd+vgAAAABJRU5ErkJggg==');
}

.photo-gallery__button:last-child::before {
	transform: rotate(180deg);
}

.photo-gallery__button:hover,
.photo-gallery__button:active {
	opacity: 1;
}

.slide-main-wrapper {
	position: relative;
}

.photo-gallery__button--first {
	position: absolute;
	bottom: 40px;
	right: 0;
	height: 80px;
	width: 250px;
	background-color: var(--activeColor);
	color: white;
	font-family: "Open Sans";
	font-size: 16px;
	font-weight: 700;
	display: flex;
	flex-direction: column;
	justify-content: center;
	cursor: pointer;
	@apply --transitionOpacityStart;
}

.photo-gallery__button--first:hover {
	@apply --transitionOpacityEnd;	
}

.photo-gallery__button--first span {
	display: block;
	text-align: left;
	padding-left: 20px;
}

.slide-wrapper {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.slide-wrapper img {
	flex-basis: 390px;
	width: 390px;
	height: 520px;
	object-fit: cover;
}

.slide-data {
	flex-basis: calc(100% - 420px);
}

.slide-data__count {
	position: relative;
	margin-left: 30px;
	color: var(--menuColor);
	font-family: "Open Sans";
	font-size: 19px;
	font-weight: 400;
}

.slide-data__count::before {
	position: absolute;
	top: 3px;
	left: -30px;
	width: 20px;
	height: 20px;
	content: "";
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUBAMAAAB/pwA+AAAAFVBMVEUAAAAfISYfISYfISYfISYfISYfISYn6cIoAAAABnRSTlMA3XhaaE7sIxJUAAAAJ0lEQVQI12NAAkxpaWnJzEAiiQpMFkFBQVFWICHCgB0gFFDXYgQAAI/RLbXqrmAoAAAAAElFTkSuQmCC');
}

.slide-data__heading {
	color: var(--menuColor);
	font-family: "Open Sans";
	font-size: 22px;
	font-weight: 400;
}

.slide-data__text {
	color: var(--menuColor);
	font-family: "Open Sans";
	font-size: 16px;
	font-weight: 400;
	width: calc(100% - 60px);
}

.gallery-next-gallery {
	position: absolute;
	top: calc(50% - 25px);
	right: 50px;
}

.gallery-next-gallery figure {
	display: flex;
	background-color: #FFF;
}

.gallery-next-gallery figcaption {
	width: 190px;
}

.gallery-next-gallery figcaption a {
	color: white;
	font-family: "Open Sans";
	font-size: 16px;
	font-weight: 700;
	background-color: var(--activeColor);
	display: block;
	padding: 10px;
	text-transform: uppercase;
	text-decoration: none;
}

.gallery-next-gallery a {
	@apply --transitionOpacityStart;
}

.gallery-next-gallery a:hover {
	@apply --transitionOpacityEnd;
}

.gallery-next-gallery figcaption p {
	color: var(--menuColor);
	font-family: "Open Sans";
	font-size: 13px;
	font-weight: 400;
	padding: 5px;
}

.gallery-next-gallery img {
	width: 100px;
	height: 100px;
	object-fit: cover;
}







@media (width <= 991px) {
	.slide-wrapper { flex-direction: column; }

	.slide-wrapper img {
		margin-left: auto;
		margin-right: auto;
	}

	.slide-data {
		text-align: center;
		margin-top: 10px;
	}

	.slide-data__text {	width: 100%; }

	.photo-gallery__buttons {
		top: 0;
		height: 520px;
		width: calc(100% - 20px);
		left: 10px;
	}

	.photo-gallery__button {
		height: 100%;
		opacity: 0;
	}

	.need-block {
		flex-wrap: wrap;
	}

	.need-block ol {
		flex-basis: 100%;
		max-width: 100%;
	}
}



@media (width <= 479px) {
	.gallery-next-gallery {
		right: 0px;
		width: 100%;
		top: 40%;
	}

	.slide-wrapper img {
		width: 100%;
		height: auto;
	}

	.photo-gallery__buttons {
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.article .cite {
		padding: 60px 20px;
	}

	.article .cite::before {
		left: 20px;
		top: 30px;
	}

	.article .cite-end {
		display: block;
		width: 100%;
	}

	.article .cite-end::before {
		top: 15px;
		left: auto;
		right: 0px;
	}

	.article-block {
		flex-wrap: wrap;
		justify-content: center;
		background-color: #FDF8F9;
		padding-top: 30px;
	}

	.article-block a:nth-child(1) {
		margin-right: 0;
	}

	.article-block a:nth-child(2) {
		flex-basis: 100%;
	}

	.article .advice {
		padding-top: 80px;
		padding-left: 40px;
	}

	.article .advice::before {
		top: 25px;
		left: calc(50% - 18px);
	}

}

