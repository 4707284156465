/* variables */
:root {
	--activeColor: #c6546e;
	--inactiveColor: #d4d4d4;
	--lightBg: #e8e8e8;
	--footerBg: #fafafa;
	--menuColor: #25262a;
	--menuColor--light: #888;
	--dateViewsColor: #736f70;
	--overlayColor: rgba(166,166,166,.5);
	--headerHeight: 260px;
	--rigthSide: 300px;
	--leftSide: calc(100% - var(--rigthSide));
}

/* properties */
:root {
	--transitionOpacityStart {
		will-change: opacity;
		opacity: 1;
		transition: opacity .3s;
	};
	--transitionOpacityEnd {
		opacity: .7;
		transition: opacity .3s;
	};
	--transitionBackgroundColor {	transition: background-color .3s;	};
	--transitionColor {	transition: color .3s;	};
	--transitionWidth { transition: width .3s; };
	--transitionAll {	transition: all .3s; }
	--fluidBorder {
		position: absolute;
		content: "";
		right: 0;
		height: 1px;
		left: 0;
		bottom: 0;
		background: currentColor;
		opacity: .25;
		transform: scaleX(.85) translateY(.085rem);
		transition: .2s;
	};
	--fluidBorderHover {
		opacity: .75;
		transform: scaleX(.95);
	}
}