@media (width <= 767px) {	.header-menu { display: none; } }





.header-menu nav {
	border-top: 1px solid var(--inactiveColor);
	padding-top: 25px;
	position: relative;
}

.header-menu nav > ul {
	display: flex;
	justify-content: space-between;
	padding-left: 0;
	list-style: none;
}

.header-menu__item > a {
	font-family: "Open Sans";
	font-size: 15px;
	font-weight: 400;
	text-transform: uppercase;
	text-decoration: none;
	padding-bottom: 10px;
}

.header-menu__item:not(.active) > a {	color: var(--menuColor); }

.header-menu__item.active > a { font-weight: bold; }
.header-menu__item.active > a,
.header-menu__item:hover > a {	color: var(--activeColor); }

.header-submenu {
	position: absolute;
	top: 70px;
	left: 0;
	width: 100%;
	display: none;
	z-index: 2;
	overflow: hidden;
	box-shadow: 0 0 0 rgba(0,0,0,0.2);
	transition: box-shadow .3s;
}

.header-submenu.submenu-is-active {
	box-shadow: 0 3px 3px rgba(0,0,0,0.2);
	transition: box-shadow .3s .3s;
}

.header-submenu-wrapper {
	display: flex;
	padding: 20px 120px 30px;
	background-color: #fafafa;
	margin-top: -400px;
	transition: margin-top .3s .5s ease-in;
}

.submenu-is-active .header-submenu-wrapper {
	margin-top: 0;
	transition: margin-top .3s ease-out;
}

.header-submenu ul {
	width: 250px;
	flex-basis: 250px;
	list-style: none;
	padding-left: 0;
}

.header-submenu ul a {
	text-decoration: none;
	position: relative;
	font-family: "Open Sans";
	color: var(--menuColor);
	font-size: 13px;
	font-weight: 400;
	line-height: 40px;
	text-transform: uppercase;
}

.header-submenu ul a::before {
	@apply --fluidBorder
}

.header-submenu ul a:hover::before {
	@apply --fluidBorderHover
}

.header-submenu section {
	display: flex;
}

.header-submenu figure {
	width: 45%;
	flex-basis: 45%;
	margin: 0 2.5%;
}

.header-submenu a img {
	width: 100%;
	@apply --transitionOpacityStart;
}

.header-submenu a:hover img{
	@apply --transitionOpacityEnd;
}

.submenu-teaser-description {
	color: var(--menuColor--light);
	font-family: "Open Sans";
	font-size: 11px;
	font-weight: 300;
	line-height: 24px;
	text-transform: uppercase;
	margin-top: 15px;
}

.submenu-teaser-heading {
	color: var(--menuColor);
	font-family: "Open Sans";
	font-size: 15px;
	font-weight: 600;
	line-height: 18px;	
	@apply --transitionOpacityStart;
}

.submenu-teaser-heading:hover {
	color: var(--menuColor);
	text-decoration: none;
	@apply --transitionOpacityEnd;
}