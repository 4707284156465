:root {
	--articleShareButton1: #6484c7;
	--articleShareButton2: #6186ad;
	--articleShareButton3: #c6546e;
}

.article-share {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-bottom: 20px;
}

.article-share__button {
	flex: 0 1 32%;
	text-align: center;
	padding: 10px 0;
	margin: 5px 0;
}

.article-share__button a {
	color: white;
	font-family: "Open Sans";
	font-size: 16px;
	font-weight: 400;
	text-decoration: none;
	position: relative;
	padding-left: 30px;
	@apply --transitionOpacityStart;
}

.article-share__button a:hover {
	@apply --transitionOpacityEnd;
}

.article-share__button a::before {
	position: absolute;
	content: "";
	top: -5px;	
	width: 30px;
	height: 30px;
}

.article-share__button:nth-child(1) a::before {
	left: 0;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeBAMAAADJHrORAAAAJ1BMVEUAAAD///////////////////////////////////////////////+uPUo5AAAADHRSTlMA4SAV+fHrz62hhGDwLQDwAAAAPElEQVQY02MYIKARcwaZyyxzBoXPdQaVz47GZztzGMU4ljMHkLk8QOUH0fgn0fin0fiHUAwAmkdrPl0AAFcxIwVtPi8eAAAAAElFTkSuQmCC');
}

.article-share__button:nth-child(2) a::before {
	left: -5px;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAMAAAAM7l6QAAAAhFBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////8g2+bRAAAAK3RSTlMA70MT+gPm3HwOB/PhwqGdkFVJIRv36tfTuHhzamJcUDQu4Mu7lnVyQD8fN2Dk4wAAAMdJREFUKM/dkFkOgyAURS8gDrW21Y5ax84t+99f5QlqYuICPD/c3MMLL2CxBLyK40QCq+faUcr1qa15SxIAhdL4ra6YTkzbRtk2CHVwVwC2vd50ugGwo5QDyHQ4Adg71G3Rcjja8VyHdT/sCqAfz4Cr0V8qnA8IQSuFv+6ei8AzexFmSp15V5cRHQ9ptbyoKXv01M6shj/V3mHQMp33Ynh+U7AunMXIR1YLcLNKJAcv36GynxobX2KEeKUeY/TR/H5kjN0SLJY/TwQqx2VXoRcAAAAASUVORK5CYII=');
}

.article-share__button:nth-child(3) a::before {
	left: -5px;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAMAAAAM7l6QAAAAYFBMVEUAAAD///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////98JRy6AAAAH3RSTlMA0ogi/Vnx2TulcCgaDMqqkGphUy/CvrmumINHRhICN0DKUgAAAINJREFUKM/Vz8kOgzAMRVFnoG6TMBU6F/z/f0kSsQADkVgh7upZZ2U4U3mb5PflmuJMp/2+342U0gHY+usHEndBuoa8pDGdMUYH/ZPKv1WqFV4ZC4CGqvj4Y1TGH3J+dUVQu+QbhfUK+iu2WDXxXOXYBleYYt9BjGoacqZ5jI2cZ+DwBp6ZB4LLpJC3AAAAAElFTkSuQmCC');
}

.article-share__button:nth-child(1) {	background-color: var(--articleShareButton1); }
.article-share__button:nth-child(2) {	background-color: var(--articleShareButton2); }
.article-share__button:nth-child(3) {	background-color: var(--articleShareButton3); }

@media (width <= 1199px) and (width >= 768px) {
	.article-share__button a {
		font-size: 14px;
	}
}

@media (width <= 991px) and (width >= 768px) {
	.article-share { justify-content: space-around; }
	.article-share__button { flex-basis: 48%; }
} 

@media (width <= 767px) {
	.article-share { justify-content: center; }
	.article-share__button {
		margin: 5px;
		flex-basis: 230px;
	}
}