.footer-bottom {
	padding-top: 50px;
	padding-bottom: 30px;
}

.footer-bottom-wrapper {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.footer-bottom-copyright,
.footer-bottom-email { display: block; }

.footer-bottom-copyright,
.footer-bottom-email,
.footer-bottom-email a {
	position: relative;
	font-family: "Open Sans";
	font-weight: 400;
	color: var(--menuColor--light);
	font-size: 15px;
	text-decoration: none;
	@apply --transitionColor;
}

.footer-bottom-email a:hover {
	color: var(--activeColor);
	@apply --transitionColor;
}

.footer-bottom-email a::before { @apply --fluidBorder; }
.footer-bottom-email a:hover::before { @apply --fluidBorderHover; }





@media (width <= 991px) {
	.footer-bottom-wrapper { display: block; }
	
	.footer-bottom-wrapper span {
		text-align: center;
		margin-bottom: 15px;
	}
}