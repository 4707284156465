.category-list ul {
	padding-left: 0;
	list-style: none;
}

.category-list li {
	border-bottom: 1px dotted var(--inactiveColor);
	padding: 5px 0;
	transition: border-color .3s;
}

.category-list li.active,
.category-list li:hover {
	border-bottom: 1px dotted var(--activeColor);
	transition: border-color .3s;
}

.category-list a {
	text-decoration: none;
	color: var(--menuColor);
	font-family: "Open Sans";
	font-size: 18px;
	font-weight: 400;
	width: 100%;
	display: inline-block;
	@apply --transitionColor
}

.category-list li.active a,
.category-list li:hover a {
	color: var(--activeColor);
	@apply --transitionColor
}