/* variables and properties */
:root {
	--viewsIco {	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAMAAABV0m3JAAAAUVBMVEUAAAB1cHF1cHF1cHF1cHF1cHF1cHF1cHF1cHF1cHF1cHF1cHF1cHF1cHF1cHF1cHF1cHF1cHF1cHF1cHF1cHF1cHF1cHF1cHF1cHF1cHF1cHHGams/AAAAG3RSTlMAEtTZLW4ONc6zeh0KdMm+iISWjWmbSSR4U1KWNtl4AAAAeUlEQVQI121P6Q6GIAzrBuPGA/U7fP8HVSTEmNhfW5umLS7Q6tUJvxI60qSCrocOvJjGac4JZnZuIiThS7ZsATNkInEG0PWNqkrhBxSSGcCXI/wfJ8YIyEZDvT8j/N7IJUvpZLdnKttt70FyB71Usr08P8s/Z0qbeQBwlATpbv7RiQAAAABJRU5ErkJggg=='); }
}

.wide-card {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	padding-bottom: 20px;
	border-bottom: 1px solid var(--lightBg);
	margin-bottom: 20px;
}

.wide-card img {
	width: 100%;
	height: auto;
}

.wide-card > a {
	min-width: 40%;
	flex-basis: 40%;
}

.wide-card > a img { @apply --transitionOpacityStart; }
.wide-card > a:hover img{	@apply --transitionOpacityEnd; }
.wide-card a { text-decoration: none; }

.wide-card figcaption {
	margin-left: 30px;
	width: calc(60% - 30px);
	flex-basis: calc(60% - 30px);
}

.section-header--wide-card { color: var(--activeColor); }

.article-header--wide-card {
	color: var(--menuColor);
	@apply --transitionColor;
}

.article-header--wide-card:hover {
	color: var(--activeColor); }

.article-excerpt--wide-card {
	font-size: 16px;
	color: var(--menuColor);
}

.date-and-views {
	display: block;
	display: flex;
	justify-content: space-between;
	color: var(--dateViewsColor);
	font-style: italic;
	font-size: 13px;
	font-weight: 300;
	font-family: "Open Sans";
}

.date-and-views__views {
	position: relative;
}

.date-and-views__views::after {
	position: absolute;
	top: 3px;
	left: -26px;
	width: 20px;
	height: 12px;
	content: "";
	@apply --viewsIco;
}

.wide-card--with-gallery > a {
	position: relative;
}

.wide-card--with-gallery > a::before{
	content: "";
	position: absolute;
	z-index: 1;
	top: 0;
	right: 0;
	width: 40px;
	height: 40px;
	background-color: var(--activeColor);
	background-image: url(../img/icon--gallery.svg);
	background-position: center;
	background-size: 20px 17px;
	background-repeat: no-repeat;
}

@media (width >= 768px) and (width <= 991px) {
	.wide-card:not(.wide-card--small) > a {
		width: 100%;
		flex-basis: 100%;
		margin-bottom: 20px;
		display: block;
		text-align: center;
	}

	.wide-card:not(.wide-card--small) img {
		max-width: 250px;
	}

	.wide-card.wide-card--large img {
		max-width: 100%;
	}

	.wide-card:not(.wide-card--small) figcaption {
		flex-basis: 100%;
		margin-left: 0;
		width: 100%;
	}
}


@media (width <= 767px) {
	.wide-card.wide-card--small img {
		max-width: 100px;
	}

	.wide-card.wide-card--small > a {
		flex-basis: 100px;
		min-width: 100px;
	}

	.wide-card.wide-card--small figcaption {
		flex-basis: calc(100% - 130px);
		min-width: calc(100% - 130px);
	}
}



@media (width <= 479px) {
	.wide-card:not(.wide-card--small) > a {
		width: 100%;
		flex-basis: 100%;
		margin-bottom: 20px;
		display: block;
		text-align: center;
	}

	.wide-card:not(.wide-card--small) img {
		max-width: 250px;
	}

	.wide-card.wide-card--large img {
		max-width: 100%;
	}

	.wide-card:not(.wide-card--small) figcaption {
		flex-basis: 100%;
		margin-left: 0;
		width: 100%;
	}	
}