/* variables */
:root {
	--paginationWidth: 10px;
	--paginationHeight: 10px;
	--paginationBg__Left: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAMAAAC67D+PAAAAP1BMVEUAAAAfISYfISYfISYfISYfISYfISYfISYfISYfISYfISYfISYfISYfISYfISYfISYfISYfISYfISYfISYfISYulmIfAAAAFXRSTlMA+sUNSAbj2LaGfm5bJBoZt66VVzEpO8BwAAAAPUlEQVQI12XKORaAIAxAwZ/EDdS4wP3PyiOUdFMMwJKg5JBkLjlDaRLyUyUi73ab7k/nekz2YcX0I+xgTgNg7AHMPxX0+wAAAABJRU5ErkJggg==');
	--paginationBg__Right: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAMAAAC67D+PAAAAP1BMVEUAAAAfISYfISYfISYfISYfISYfISYfISYfISYfISYfISYfISYfISYfISYfISYfISYfISYfISYfISYfISYfISYulmIfAAAAFXRSTlMA+gfFt0gkGQ7jhn5uW9jXrpRYMBzjVDjJAAAAPklEQVQI12XJOxKAIBAD0KyriCB/7n9WAg0FKTJvEvgKuAczvwR42Xan+TcUMdSb1Jrvvqh4ClktdIoruy8NZSgBxs/DYq0AAAAASUVORK5CYII=');
	--paginationIcon {
		width: var(--paginationWidth);
		height: var(--paginationHeight);
		display: block;
		position: absolute;
		content: "";
		top: 6px;
	}
}

.page-pagination {
	margin-top: 80px;
	margin-bottom: 40px;
}

.page-pagination ul {
	padding-left: 0;
	list-style: none;
	width: 100%;
	text-align: center;
}

.page-pagination li {
	display: inline-block;
	text-align: center;
}

.page-pagination li.disabled {
	opacity: 0.7;
}

.page-pagination li:first-child {
	float: left;
	padding-left: 15px;
	position: relative;
}

.page-pagination li:first-child::before {
	@apply --paginationIcon;
	background-image: var(--paginationBg__Left);
	left: 0;
}

.page-pagination li:last-child {
	float: right;
	padding-right: 15px;
	position: relative;
}

.page-pagination li:last-child::before {
	@apply --paginationIcon;
	background-image: var(--paginationBg__Right);
	right: 0;
}

.page-pagination li:not(:first-child):not(:last-child) {
	margin: 0 10px;
}

.page-pagination a {
	text-decoration: none;
	color: var(--menuColor);
	font-family: "Open Sans";
	font-size: 13px;
	font-weight: 400;
	text-transform: uppercase;
	@apply --transitionColor;
}

.page-pagination li.active a,
.page-pagination li:not(.disabled):hover a {
	color: var(--activeColor);
	@apply --transitionColor;
}



@media (width <= 479px) {
	.page-pagination span {
		display: none;
	}
}