.tags .tag {
	color: var(--menuColor);
	background-color: var(--inactiveColor);
	font-family: "Open Sans";
	font-size: 10px;
	font-weight: 400;
	display: inline-block;
	margin-right: 10px;
	margin-bottom: 10px;
	padding: 5px 13px;
	text-transform: uppercase;
	text-decoration: none;
	@apply --transitionOpacityStart;
}

.tags .tag:hover {
	@apply --transitionOpacityEnd;
}