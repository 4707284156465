.breadcrumbs {
	border-top: 1px solid var(--inactiveColor);
	margin-top: 13px;
	margin-bottom: 15px;
	padding: 15px 0;
}

.breadcrumbs a {
	font-family: "Open Sans";
	font-size: 12px;
	font-weight: 400;
	text-transform: uppercase;
}

.breadcrumbs a:not(:first-child) {
	margin-left: 15px;
	position: relative;
}

.breadcrumbs a:not(:first-child)::after {
	position: absolute;
	display: block;
	content:"/";
	font-size: 12px;
	top: 0;
	left: -10px;
}

.breadcrumbs a:not(.active) {
	color: var(--menuColor);
	@apply --transitionColor;
}

.breadcrumbs .active,
.breadcrumbs a:hover,
.breadcrumbs a:active {
	color: var(--activeColor);
}

@media (width <= 767px) {
	.breadcrumbs {
		margin-top: 40px;
		border-top: none;
		text-align: center;
		padding-left: 15px;
		padding-right: 15px;
	}
}