.article-header {
	margin-bottom: 20px;
}
.article-header h1 {
	color: var(--menuColor);
	font-family: "Open Sans";
	font-size: 32px;
	font-weight: 400;
	margin-bottom: 15px;
}

.article-header figure {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.article-header figure img {
	border-radius: 50%;
	filter: grayscale(100%);
	transition: filter .3s;
	width: 100px;
	height: 100px;
	object-fit: cover;
}

.article-header figure img:hover {
	filter: grayscale(0);
}

.article-header figcaption {
	margin-left: 20px;
}

.article-header .author-link {
	font-family: "Open Sans";
	font-weight: 400;
	color: var(--activeColor);
	font-size: 14px;
	text-decoration: none;
	@apply --transitionOpacityStart;
}

.article-header .author-link:hover {
	@apply --transitionOpacityEnd;
}

.article-header .author-tags {
	margin-top: -10px;
	margin-bottom: 10px;
}

.article-header .author-tags a {
	font-family: "Open Sans";
	font-weight: 400;
	font-size: 12px;
	color: var(--menuColor);
	text-decoration: none;
	position: relative;
	@apply --transitionOpacityStart;
}

.article-header .author-tags a:hover {
	@apply --transitionOpacityEnd;
}

.article-header .author-tags a:not(:last-child) {
	margin-right: 10px;
}

.article-header .author-tags a:not(:last-child)::before {
	content: ",";
	position: absolute;
	bottom: 0;
	right: -3px;
}