/* variables and properties */
:root {
	--sidebar-social__link {
		width: 35px;
		height: 20px;
		margin: 0 2px;
		display: inline-block;
		filter: grayscale(1);
	}
	--sidebar-social__link--vk { background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAUCAMAAAAweHR+AAAAdVBMVEUAAADjU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU28eu1lJAAAAJnRSTlMA7alw/OLedwv1u08SBQHv58WwrWVDOivq1NCdlYyEX1kwIKVrSJXZ3ZIAAADfSURBVCjPlZHZkoIwEEVvFkgA2UFm3EYdzf9/op2OqChW6XnJye1bFJXGlyzMWjZye8ALrSCinuxUOk/MsYqJqOr4InnwW5D2rBqEFY5R3FmGy5k0194SEF1IfyyYlG+118pbSWKj0PkDHj+UkfKkIjEhazCS3v5IeImAY+LYFhgZNCcGlk+BvGbRS9zZhEgpx3RtOM3k8YSbYVXgkf1cJ8WU/7mSelpV/EHpkNxHon5T6stbJ4MZdY8Jw+qa6xzYXT05YkJuRNgjebFxgR2esNlWShn2mLVpI+V6wGdcACAdLLWNVIFWAAAAAElFTkSuQmCC');}
	--sidebar-social__link--fb { background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAUCAMAAAAweHR+AAAAQlBMVEUAAADjU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2+/vJPFAAAAFXRSTlMAllpn4aqfMrstwlM4/teseywgDgv7vcL5AAAAW0lEQVQoz93PSw6AMAhFUZQWsK1/2f9WxYEjIzYxTrxTTkIeXFqRYiTw6oIeuWbQR1Ps3if/VzYzgl9rpvHAhkhmEmK+36Rn4ZWZKwwwTwYi81Kz62+miAjCN+0biQdMxVURdgAAAABJRU5ErkJggg==');}
	--sidebar-social__link--tw { background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAUCAMAAAAweHR+AAAAaVBMVEUAAADjU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2+MVbn6AAAAInRSTlMA+w/gixYJ72lBLQHCvqGedTw22tfTzaKDJB/ktrGVX0xLuP8ARAAAAKRJREFUKM+dz9kSgyAMheEDgqJY97173v8hO1pqx0Bv+l1mfjIEQbFGiLT4aHJBkbHSxKxRCk4taNWJyrLmRKV71tNbV6asuRD122wmRxgw1TrO7xoNOaV/SkubZCBnAleMxBhwirgU3JKwJInhqc90UMCnp+jQZPDZ3Fvj0yf6iiTC0r1KMoRl474lmCzqun9Z3CSCZDUIWrVqxm+yeTxrjb+8ABwNHDrYgS9VAAAAAElFTkSuQmCC');}
	--sidebar-social__link--ig { background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAUCAMAAAAweHR+AAAAM1BMVEUAAADjU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU29Xj7IhAAAAEHRSTlMAv4BA7zAQ369wYJ8gj1DPN5jghgAAAKtJREFUKM+Vkd0OwyAIRkHx37rv/Z92re3qGnTJzoUGPEEF+g+fmNEJHPNUcYJv2E+qCKK7gxywaSeBR1CiA3ShgtZvNCZVAmyEVQ4fOR+xIzklMlNnXzZIagXHsYGZOhXizqetnXx9RrByRj78cCwCUd/WDr0QKzWBWTnunkehw2nz/pDfWDifPdX9MXgOKKAqpwJ5RL6ASZOBwB+kd1PTXhjESnO8vXjWeANFEwiTl9LKkwAAAABJRU5ErkJggg==');}
	--sidebar-social__link--pi { background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAUCAMAAAAweHR+AAAAhFBMVEUAAADjU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU28Yl0tfAAAAK3RSTlMAq/rpA/HtLCf25LKFeWdbQw0JzsamnpRtSD44ItrUvKCbVk8cFxO7jHAypeZhaQAAANdJREFUKM+VzrmWgkAURdFbVYwCCjgwams79HD+//+aZS8jisATvOTu4On9fouzadZ3LTfseGYvjyXSWl6tYj9ZA1GzKa+5Azf6yJjCoVJSjqoMfPjMeSIajkzzQxl0c1KnRFUShlkIRjGYuenhogODWrCBHKu52UCh0/H/9UCn6fhMrt1K2sN0nc/0sJtgHUSwVwJOsyoLRbfVHWysHDLN2wO2kVpTKk7h02MSC9OwrqWrg20gTz/ArU7DbQSEsbzlOJU8i3ot1BkZAMxNywXf2ZcpEr3dHzEZGQjYhwuIAAAAAElFTkSuQmCC');}
	--sidebar-social__link--gp { background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACMAAAAUCAMAAAAweHR+AAAAb1BMVEUAAADjU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU2/jU29n+csIAAAAJHRSTlMA8fzi3beiVRvsypKGf0nDqqiba1AuJgwF5tJ0ZTLArVtAHh3M1EyhAAAArklEQVQoz53RyQ7DIAxFUUMgCZB5Hju+///G0ijLYKm9C6+OBLLp58Y2rqoqThlSGB0LuIkhb1ET9VK+GJPj4adGwpgYm5/ZYW762jjc/Wyw+ymiwJeBjlJjiTHkIK1RKZVKSalUfYn6LUJLFOGopOtqzAP71tjlGogGxiRGZEm7wIZNL+fvoaYFO4XKsJ7rLhiTn+sewleH6IjGVZYUrgGEkrApMT2d1k0y0R99AESiCjhwUd00AAAAAElFTkSuQmCC');}
}
.sidebar-menu {
	z-index: 11;
	position: absolute;
	top: 100px;
}

#navbar {
	position: absolute;
	width: 300px;
	background-color: white;
}

.sidebar-menu .header-top__logo-wrapper {
	margin-top: 20px;
}

.sidebar-menu .navbar-nav {
	margin-top: 100px;
	margin-bottom: 30px;
	width: 100%;
}

.navbar-nav > li {
	float: none;
}

.navbar-nav > li > a {
	font-family: "Open Sans";
	font-size: 15px;
	font-weight: 400;
	text-transform: uppercase;
	color: var(--menuColor);
	padding-left: 5px;
	padding-right: 5px;
	margin-left: 10px;
	margin-right: 10px;
	text-align: center;
}

.navbar-nav > li > a::before {
	@apply --fluidBorder;
}

.nav > li > a:hover::before,
.nav > li.active > a::before,
.nav > li.open > a::before {
	@apply --fluidBorderHover;
}

.nav > li > a:hover,
.nav > li > a:focus,
.nav > li.active > a {
	color: var(--activeColor);
	background-color: transparent;
}

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
	background-color: transparent;
	border-color: var(--inactiveColor);
}

.nav > li.active > a {
	font-weight: bold;
}

.dropdown-menu {
	top: 0;
	left: 100%;
	background-color: var(--lightBg);
	box-shadow: none;
}

@media (width >= 992px) {
	.dropdown-menu::after {
		position: absolute;
		display: block;
		content: "";
		width: 0;
		height: 0;
		top: 20px;
		border-style: solid;
		border-width: 7px 8px 7px 0;
		border-color: transparent #e8e8e8 transparent transparent;
		animation: easy-slide .3s forwards ease-out;
	}

	@keyframes easy-slide { from { left: 0px; } to { left: -8px; }  }
}

.navbar-nav > li > .dropdown-menu {
	border: none;
	border-radius: 0;
}

.dropdown-menu > li > a {
	font-family: "Open Sans";
	font-size: 12px;
	font-weight: 300;
	text-transform: uppercase;
	text-align: center;
	line-height: 30px;
	color: var(--menuColor);
}

.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
	background-color: transparent;
	color: var(--activeColor);	
}

.sidebar-social {
	margin-bottom: 30px;
	text-align: center;
}

.sidebar-social__link {
	@apply --sidebar-social__link;
	@apply --transitionAll;
}

.sidebar-social__link:hover {
	filter: grayscale(0);
	@apply --transitionAll;
}

.sidebar-social__link--vk {	@apply --sidebar-social__link--vk }
.sidebar-social__link--fb {	@apply --sidebar-social__link--fb }
.sidebar-social__link--tw {	@apply --sidebar-social__link--tw }
.sidebar-social__link--ig {	@apply --sidebar-social__link--ig }
.sidebar-social__link--pi {	@apply --sidebar-social__link--pi }
.sidebar-social__link--gp {	@apply --sidebar-social__link--gp }

.techinacal-pages {
	list-style: none;
	padding-left: 0;
}

.techinacal-pages li a {
	color: var(--menuColor);
	font-family: "Open Sans";
	font-size: 11px;
	font-weight: 400;
	text-transform: uppercase;
	margin: 10px 20px;
	display: inline-block;
	text-decoration: none;
	@apply --transitionColor;
}

.techinacal-pages li:hover a {
	color: var(--activeColor);
	@apply --transitionColor;
}





@media (width >= 768px) {
	#navbar { height: auto !important; }

	.collapse:not(.in) {
		margin-left: -300px;
		@apply --transitionAll;
	}

	.collapse.in {
		margin-left: 0;
		@apply --transitionAll;
	}
}





@media (width <= 991px) {
	.sidebar-menu .navbar-nav { width: auto; }
}





@media (width <= 767px) {
	.sidebar-menu {
		position: static;
		width: 100%;
	}

	#navbar {
		position: static;
		width: 100%;
	}

	.sidebar-menu .navbar-nav { margin-top: 40px; }
	.sidebar-menu .header-top__logo-wrapper { display: none; }
	.sidebar-social { margin-bottom: 15px; }
	.sidebar-social__link { margin: 0 4px; }
	.techinacal-pages { text-align: center; }
}