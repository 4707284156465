.right-side {
	width: var(--rigthSide);
}

@media (width <= 767px) {
	.right-side {
		width: 100%;
		max-width: 450px;
		margin: 0 auto;
		padding: 15px;
	}
}