.small-teaser {
	width: 100%;
	margin-bottom: 20px;
	position: relative;
}

.small-teaser img {
	width: 100%;
	@apply --transitionOpacityStart;
}

.small-teaser img:hover { @apply --transitionOpacityEnd; }

.small-teaser a {
	text-decoration: none;
}

.small-teaser figcaption {
	position: absolute;
	bottom: 0;
	width: 100%;
	padding: 15px;
	background-color: var(--activeColor);
	opacity: 0;
	@apply --transitionAll;
}

.small-teaser .article-header--small-teaser {
	color: #FFF;
	margin: 0;
	font-size: 16px;
	font-weight: 400;
}

.small-teaser:hover figcaption {
	opacity: 1;
	@apply --transitionAll;
}