.instagram-cards {
	display: flex;
	flex-wrap: wrap;
	margin-left: -1px;
	margin-right: -1px;
	margin-bottom: 20px;
}

.instagram-cards a {
	display: block;
	width: 100px;
	height: auto;
	padding: 1px;
	margin-bottom: 1px;
	flex-basis: 33.33%;
}

.instagram-cards img {
	width: 100%;
	height: auto;
	object-fit: cover;
	@apply --transitionOpacityStart;
}

.instagram-cards img:hover {
	@apply --transitionOpacityEnd;
}