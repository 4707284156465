.site-main {
	position: relative;
	z-index: 1;
	background-color: #FFF;
	box-shadow: 0 3px 3px rgba(0,0,0,0.2);
	margin-bottom: var(--headerHeight);
}

.site-main .demo {
	padding: 100px 0 500px;
	text-align: center;
}





@media (width <= 991px) {
	.site-main { margin-bottom: calc(var(--headerHeight) * 1.6);	}
}




@media (width <= 479px) {
	.site-main { margin-bottom: calc(var(--headerHeight) * 1.65);	}
}